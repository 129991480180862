let development={
  //url:'http://localhost:8080/',
url:'http://survey.geomaticxmap.in:3000/',
//sessionkey:'_surveyDashboard'

}

let production={
    url:'',
    sessionkey:'_surveyDashboard'
    
}
let config=(process.env.REACT_APP_ENVIRONMENT==='production')?production:development;

export default config;
