
import {Outlet} from 'react-router-dom'
import Menu from '../components/Common/Menu'
import Header from '../components/Common/Header'
import Footer from '../components/Common/Footer'
export default function MapLayout(){


return(
<>

<Menu className={"map-sidemenu"}></Menu>
<div className="wrapper d-flex flex-column bg-light wrapper-map">
    
     <div className="body flex-grow-1 px-3">
        <div className="container-lg container-map">
             <Outlet></Outlet>
        </div>
    </div>
    <Footer></Footer>
</div>


</>

) 

}