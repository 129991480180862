import React, { useEffect,useState } from 'react'
import { GoogleMap, useJsApiLoader,MarkerClusterer,Marker,Polyline } from '@react-google-maps/api';
import common from '../../utility/commonclass';
const containerStyle = {
  width: '100%',
  height: '90vh'
};

const center = {
  lat: 28.5355,
  lng: 77.3910
};

function MyComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAgIVtPj9I-jXY3fOUkV8k9CArQRQ7dkhc"
  })
  const [polelist, setmapItems] = React.useState([]);
  const [cabellist, setmapPolyline] = React.useState([]);
  const [label, setlabel] = useState(props.label);
  const [map, setMap] = React.useState(null);
  useEffect(()=>{

    console.log(props.list);
    let p=[];
    let c=[];
    for(let i=0; i<=props?.list?.length;i++){
       // console.log(props?.list[i]);
        if(props?.list[i]?.ext_id==3 || props?.list[i]?.ext_id==7 || props?.list[i]?.ext_id==11 || props?.list[i]?.ext_id==17){
           c.push(props?.list[i]); 
        }else{
            p.push(props?.list[i]); 
        }

    }
    
    setmapItems(p);
    setmapPolyline(c);
  },[props.list])


  useEffect(()=>{
    console.log(props.label);
    setlabel(props.label);
  },[props.label])

  const onLoad = React.useCallback(function callback(map) {
    console.log('load')
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    //const bounds = new window.google.maps.LatLngBounds(center);
    //map.fitBounds(bounds);

    setMap(map)
  }, [])

  function createKey(location) {
    return location.lat + location.lng
  }

  function getlocation(item) {
      try{
        if(item?.coordinates =='undefined'){
            return { lat: 0, lng: 0 }
        }
     
      let coordinates = JSON.parse(item?.coordinates);
      return { lat: coordinates[0]['latitude'], lng: coordinates[0]['longitude'] }
      }catch{
        return { lat: 0, lng: 0 }

      }
      
    //return location.lat + location.lng
  }

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const getColor = (item) => {
    let url = "http://survey.geomaticxmap.in/api/images/";
    let color = "black.png";
    if (item?.ext_id == 2) {  // Pole
        if (item?.kvfeeder33_id > 0 && item?.kvfeeder11_id == 0) { // 33 KV
            color = "33kv.png";
            if(item?.no_branch > 0 && item?.cut_point > 0 ){
                color = "33kv_branch_cut.png";
            }else if(item?.no_branch > 0 && item?.cut_point == 0 ){
                color = "33kv_branch.png";
            }else if(item?.no_branch == 0 && item?.cut_point > 0 ){
                color = "33kv_branch.png";
            }
        } else if (item?.kvfeeder33_id > 0 && item?.kvfeeder11_id > 0 && item?.dt_id == 0) { //11
            color = "11kv.png";
            if(item?.no_branch > 0 && item?.cut_point > 0 ){
                color = "11kv_branch_cut.png";
            }else if(item?.no_branch > 0 && item?.cut_point == 0 ){
                color = "11kv_branch.png";
            }else if(item?.no_branch == 0 && item?.cut_point > 0 ){
                color = "11kv_branch.png";
            }
        } else if (item?.kvfeeder33_id > 0 && item?.kvfeeder11_id > 0 && item?.dt_id > 0) { // LT
            color = "lt.png";
            if(item?.no_branch > 0 && item?.cut_point > 0 ){
                color = "lt_branch_cut.png";
            }else if(item?.no_branch > 0 && item?.cut_point == 0 ){
                color = "lt_branch.png";
            }else if(item?.no_branch == 0 && item?.cut_point > 0 ){
                color = "lt_branch.png";
            }
        }

    } else if (item?.ext_id == 4) { // comms_device
        //color="black";

    } else if (item?.ext_id == 6) { //Connector Point
        // color="blueviolet";

    } else if (item?.ext_id == 8) { //Consumer_Meter
        //  color="aqua";

    } else if (item?.ext_id == 9) { //energy_source
        // color="green";

    } else if (item?.ext_id == 10) { //energy_storage
        // color="greenyellow";

    } else if (item?.ext_id == 12) { //Measuring Equipment
        // color="brown";

    } else if (item?.ext_id == 13) { //Meter
        //  color="skyblue";

    } else if (item?.ext_id == 14) { //Transformer
        color = "transformer.png";

    } else if (item?.ext_id == 15) { //Protective Equipment
        // color="darkorange";

    } else if (item?.ext_id == 16) { //service_point
        // color="yellow";


    }
    return url + color;


}

const getLabel = (item)=>{
      
  if(common.isEmpty(label[item?.ext_id])==false){
          let code="";

          if(label[item?.ext_id]['status']==true){

              label[item.ext_id]['type'].map((codeval)=>{
                  if(codeval=='code'){
                      code  =code +' '+(item?.record_number !=""?item?.record_number:"TMP : "+item?.mobile_record_number ) +" :: ";

                  }else  if(codeval=='paint_code'){
                      code  =code +' '+item?.paint_code +" :: ";
                  }else  if(label[item.ext_id]['type']=='nominal_voltage'){
                      code  =code +' '+item?.nominal_voltage +" :: ";
                  }else  if(label[item.ext_id]['type']=='composite_type'){
                      code  =code +' '+item?.composite_type +" :: ";
                  }else  if(label[item.ext_id]['type']=='element_type'){
                      code  =code +' '+item?.element_type +" :: ";
                  }else  if(label[item.ext_id]['type']=='no_of_hv'){
                      code  =code +' '+item?.no_of_hv +" :: ";
                  }else  if(label[item.ext_id]['type']=='no_of_mv'){
                      code  =code +' '+item?.no_of_mv +" :: ";
                  }else  if(label[item.ext_id]['type']=='no_of_mv'){
                      code  =code +' '+item?.no_of_mv +" :: ";
                  }else  if(label[item?.ext_id]['type']=='no_of_lv'){
                      code  =code +' '+item?.no_of_lv +" :: ";
                  }else  if(label[item.ext_id]['type']=='no_branch'){
                      code  =code +' '+item?.no_branch +" :: ";
                  }else  if(label[item.ext_id]['type']=='jumper'){
                      code  =code +' '+item?.jumper +" :: ";
                  }else  if(label[item.ext_id]['type']=='cut_point'){
                      code  =code +' '+item?.cut_point +" :: ";
                  }

              })                    

          }

          return code;
      
  }else{
      return "";
  }

  
}

  return isLoaded ? (
     <> <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/*{getLabel(location)}*/}
        { /* Child components, such as markers, info windows, etc. */ }
        
        <MarkerClusterer minimumClusterSize={5} onClick={(c,e)=>{
          
        }}>
          {(clusterer) =>
            polelist.map((location) => (
              <Marker key={location?.id} id={location?.id} position={getlocation(location)}  icon={getColor(location)} 
              label={getLabel(location)}
              clusterer={clusterer}              
              onClick={(e, mitem) => { props?.openElementDetail(location?.id, e) }}/>
            ))
          }
        </MarkerClusterer>
        {cabellist.length > 0 && 

cabellist.map(item=>{
    let coordinates = JSON.parse(item?.coordinates);
    let strokeColor = "#FF0000";
    let strokeWeight = 1;
    let path=[]

    if(coordinates.length > 1){
        path = [
            { lat: coordinates[0]['latitude'], lng: coordinates[0]['longitude'] },
            { lat: coordinates[1]['latitude'], lng: coordinates[1]['longitude'] },
        ]
    }
    

    if (item?.kvfeeder33_id > 0 && item?.kvfeeder11_id == 0) {
        strokeColor = "#FF0000";
        strokeWeight = 3;
    }
    if (item?.kvfeeder33_id > 0 && item?.kvfeeder11_id > 0 && item?.dt_id == 0) {
        strokeColor = "#0000FF";
        strokeWeight = 2;
    }
    if (item?.kvfeeder33_id > 0 && item?.kvfeeder11_id > 0 && item?.dt_id > 0) {
        strokeColor = "#008000";
        strokeWeight = 1;
    }
    return (<Polyline

        key={item?.id}
        path={path}
        options={{
            strokeColor: strokeColor,
            strokeOpacity: 0.8,
            strokeWeight: strokeWeight,
            fillColor: strokeColor,
            fillOpacity: 0.35,
            clickable: true,
            draggable: true,
            editable: false,
            visible: true,
            radius: 30000,
            zIndex: 1,
            icons: [{ 
                icon: "hello",
                offset: '0',
                repeat: '10px'
              }],
        }}
    />)
})

                    }
      </GoogleMap></>
  ) : <></>
}

export default React.memo(MyComponent)