import { Button, Form, Input, Switch, notification, Select, Card, Col, Row, Popconfirm, message } from 'antd';
import { useState, useEffect } from 'react';
import CustomerObj from '../../utility/customerclass';
import { EditOutlined, DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import { useParams, useNavigate,Link } from 'react-router-dom';
import common from '../../utility/commonclass';
import reportObj from '../../utility/reportclass';
const { Meta } = Card;
const fixAttribute=['tp_migration_unique_id','tp_dt_uq_nameplate_photo','tp_dt_uq_structure_photo','tp_pole_paint_code','tp_no_of_hv_circuits','tp_no_of_lv_circuits','tp_no_of_mv_circuits']
const CollectionLayerForm = () => {

    const [formdata, setFormData] = useState([]);
    const [layerDetail, setlayerDetail] = useState({});
    const [deletedids, setdeletedid] = useState([]);
    const [formError, setFormError] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        async function getData() {

            const data = await reportObj.getCollectionLayerForm({ ext_id: id });
            const filterData = data.map((item, itemkey) => {
                let tmp = item;
                tmp['field_sort'] = (tmp['field_sort'] > 0) ? tmp['field_sort'] : itemkey + 1;
                return tmp;
            })
            setFormData(filterData);
        }

        async function getLayerDetail() {

            const data = await reportObj.getCollectionLayerDetail({ id: id });
            console.log(data);
            setlayerDetail(data);
        }

        getData();
        getLayerDetail();


    }, [id])


    const handleInput = (evt, key) => {

        const tmp = [...formdata];
        tmp[key][evt.target.name] = evt.target.value;
        console.log(tmp);
        setFormData(tmp);

        //  this.setState({ [evt.target.name]: evt.target.value });
    }
    const addnew = () => {
        const sort = formdata.length + 1;
        let tmp = {
            id: "",
            ext_id: id,
            project_id: 1,
            field_name: "",
            field_label: "",
            data_type: "CHAR",
            mandatory: "0",
            field_size: "",
            field_values: "",
            field_sort: sort,
            isMobile: 1
        }
        setFormData((pre) => ([...pre, tmp]));
        console.log('click data');

    }

    const saveForm = async () => {


        const error = await validateForm();
        setFormError(error);
        if (Object.keys(error).length > 0) {

            notification.error({ message: 'Please Fill All Fields Data' });
            return false;
        }
        console.log(formdata);
        //return false;
        const response = await reportObj.updateFormData({ formdata: formdata, deletedata: deletedids });

        if (response.status == 1) {
            notification.success({ message: response.message })
        } else {
            notification.error({ message: response.errormessage })
        }
    }

    const validateForm = () => {
        const error = {};

        for (let item in formdata) {
            console.log(formdata[item]);
            if (formdata[item]['field_label'] == '' || formdata[item]['field_name'] == '' || (formdata[item]['data_type'] == 'ENUM' && formdata[item]['field_values'] == '')) {
                error[item] = "Please fill all required values";
            }


        }
        return error;


    }

    const confirm = (e, key) => {

        console.log(e);
        console.log(key);
        let tmp = formdata;
        let deleteid = tmp[key].id;
        deletedids.push(deleteid);
        setdeletedid(deletedids);

        tmp.splice(key, 1)


        setFormData((pre) => ([...tmp]));
        console.log(deletedids);
        //setFormData(tmp);
        console.log(formdata);

        message.success('Click on Yes');

    };
    const cancel = (e) => {
        console.log(e);
        message.error('Click on No');
    };
    return (
        <>
            <div className="card mb-4">
                <div className="card-header">{layerDetail.name} Create Form</div>

                <div className="card-body">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Sr.</th>
                                <th>Field Sort</th>
                                <th>Form Label</th>
                                <th>Form Name</th>
                                <th>Field Size</th>
                                <th>Data Type</th>
                                <th>Select Box Value</th>
                                <th>Mandatory</th>
                                <th>Is Mobile</th>
                                <th>Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            {formdata.map((item, key) => {

                                return (<tr key={key}>
                                    <td>{key + 1}</td>
                                    <td> <input className="form-control" name="field_sort" value={(item.field_sort > 0) ? item.field_sort :1} onChange={(e) => handleInput(e, key)}></input></td>
                                    <td><input className="form-control" name="field_label"
                                                onChange={(e) => handleInput(e, key)} value={item.field_label} ></input></td>
                                    <td>  <input className="form-control" name="field_name" value={item.field_name} onChange={(e) => handleInput(e, key)}></input></td>
                                    <td><input className="form-control" name="field_size" value={item.field_size} onChange={(e) => handleInput(e, key)}></input></td>
                                    <td><select className="form-control" name="data_type" value={item.data_type} onChange={(e) => handleInput(e, key)}>
                                                <option>Select Datatype</option>
                                                <option value="CHAR">Text</option>
                                                <option value="INTEGER">INTEGER</option>
                                                <option value="DATE">DATE</option>
                                                <option value="FLOAT">FLOAT</option>
                                                <option value="ENUM">ENUM</option>
                                                <option value="Image">Image</option>
                                            </select></td>
                                    <td>{item.data_type == 'ENUM' && <>
                                       
                                                <input className="form-control" name="field_values" value={item.field_values} onChange={(e) => handleInput(e, key)}></input>
                                            </>}</td>
                                    <td><select className="form-control" name="mandatory" value={item.mandatory} onChange={(e) => handleInput(e, key)}>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>

                                            </select></td>
                                            <td><select className="form-control" name="isMobile" value={item.isMobile} onChange={(e) => handleInput(e, key)}>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>

                                            </select></td>
                                    <td>
                                        {fixAttribute.indexOf(item.field_name)< 0 &&  <Link to="" onClick={(e)=>confirm(e, key)}><DeleteOutlined key="delete" /> </Link>}
                                       </td>

                                </tr>)

                            })}
                        </tbody>
                    </table>

                   {/* <Row gutter={[12, 12]}>
                        {formdata.map((item, key) => {
                            const err = (formError[key] == '' || typeof (formError[key]) == 'undefined') ? '' : 'form-error';
                            return (
                                <Col span={12} key={key} className={err}><Card
                                    style={{
                                        width: '90%',
                                    }}
                                    actions={[
                                        <Popconfirm
                                            title="Are you sure to delete this task?"
                                            onConfirm={(e) => confirm(e, key)}
                                            onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <DeleteOutlined key="delete" />
                                        </Popconfirm>,

                                    ]}
                                >
                                    <div>{key + 1}</div>
                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-3 col-form-label">Field Sort</label>
                                        <div className="col-sm-7">
                                            <input className="form-control" name="field_sort" value={(item.field_sort > 0) ? item.field_sort : (key + 1)} onChange={(e) => handleInput(e, key)}></input>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-3 col-form-label">Form label</label>
                                        <div className="col-sm-7">
                                            <input className="form-control" name="field_label"
                                                onChange={(e) => handleInput(e, key)} value={item.field_label} ></input>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-3 col-form-label">Form Name</label>
                                        <div className="col-sm-7">
                                            <input className="form-control" name="field_name" value={item.field_name} onChange={(e) => handleInput(e, key)}></input>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-3 col-form-label">Field Size</label>
                                        <div className="col-sm-7">
                                            <input className="form-control" name="field_size" value={item.field_size} onChange={(e) => handleInput(e, key)}></input>
                                        </div>
                                    </div>


                                    <br />
                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-3 col-form-label">Data Type</label>
                                        <div className="col-sm-7">
                                            <select className="form-control" name="data_type" value={item.data_type} onChange={(e) => handleInput(e, key)}>
                                                <option>Select Datatype</option>
                                                <option value="CHAR">Text</option>
                                                <option value="INTEGER">INTEGER</option>
                                                <option value="DATE">DATE</option>
                                                <option value="FLOAT">FLOAT</option>
                                                <option value="ENUM">ENUM</option>
                                                <option value="Image">Image</option>
                                            </select>
                                        </div>
                                    </div>
                                    {item.data_type == 'ENUM' && <><br />
                                        <div className="form-group row">
                                            <label htmlFor="" className="col-sm-3 col-form-label">Select Box Value</label>
                                            <div className="col-sm-7">
                                                <input className="form-control" name="field_values" value={item.field_values} onChange={(e) => handleInput(e, key)}></input>
                                            </div>
                                        </div></>}
                                    <br />
                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-3 col-form-label">Mandatory</label>
                                        <div className="col-sm-7">
                                            <select className="form-control" name="mandatory" value={item.mandatory} onChange={(e) => handleInput(e, key)}>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>

                                            </select>
                                        </div>
                                    </div>


                                </Card>
                                </Col>
                            )

                        })}
                    </Row>*/}
                    <br />
                    <Row>
                        <Col span={12} >
                            <Button type="primary" size="small" onClick={(e) => { addnew() }}>Create New Field</Button>
                        </Col>
                    </Row>


                    <Row>
                        <Col span={24} className="text-center" >
                            <Button type="primary" size="large" onClick={(e) => { saveForm() }}>Save Form</Button>
                        </Col>
                    </Row>

                </div>
            </div>
        </>)
}

export default CollectionLayerForm;