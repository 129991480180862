import { useEffect, useState } from 'react';
import { getUsers } from '../../services/user.server';

import ReportObj from '../../utility/reportclass';
import CustomerObj from '../../utility/customerclass';
import { Button, Pagination, Popconfirm, notification, Modal, Empty, AutoComplete, Input, DatePicker,Select } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import { useNavigate,Link } from "react-router-dom";
const searchResult = (data) => {

    return data.map((item, itemkey) => {
        return {
            value: item.first_name+' '+item.last_name+' '+item.mobile, label: (
                <>{`${item.first_name} ${item.last_name} (${item.mobile})`}</>
            ),
            key: item.id,
            user:item
        }
    })
}
const Tasklist = () => {
    const [customerList, setReportList] = useState({ totalrecord: 0, list: [] });
    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 10 });
    const [filter, setFilter] = useState({});
    const [search, setSearch] = useState("");
    const [useroptions, setuseroptions] = useState([]);
    const [loader, setloader] = useState(false);
    const [workTypeData, setworkTypeData] = useState([]);
    let navigate = useNavigate();
    useEffect(() => {
        console.log('call customer useEffect');
        ReportObj.getTaskList({ page: paging.currentpage, limit: paging.itemlimit, filter: filter }).then(list => {
            setReportList(() => {
                return { totalrecord: list.count, list: list.rows };
            })
        });
    }, [paging]);

    useEffect(() => {
        getWorkType();
    }, []);


    async function getWorkType() {
        const worktypelist = await CustomerObj.getWorkType({});
        console.log(worktypelist);
        const worktypefiletrlist = worktypelist.map((item) => {
          return { value: item.id, label: `${item.work_name}` }
  
        })      
        setworkTypeData(worktypefiletrlist);
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        setpage((pre) => {
            return { ...pre, currentpage: 1 }
        })
        getUsers({ page: paging.currentpage, limit: paging.itemlimit, filter: filter }).then(list => {
            setReportList(() => {
                return { totalrecord: list.count, list: list.rows };
            })

        });
    }
    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFilter((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = value;
            return { ...pre, ...newobj }

        })
    }
    const handleUserChange = (value) => {
        console.log('handleUserChange');
        console.log(value);
        setSearch(value);
    }
    const handleSearch = (value,index) => {
        console.log(value);
        let filter = { searchkey: value };
        setloader(true);
        CustomerObj.getCustomers({ filter: filter }).then((data) => {
            setloader(false);
            console.log(searchResult(data.rows));
            setuseroptions(value ? searchResult(data.rows) : []);
        }).catch((err) => {

        })
    };

    const onSelect = (value,item) => {
        console.log(`Value ${value} index ${item}`);
        console.log(item);
     let name = 'user_id';
   
     setFilter((pre) => {
         console.log(pre);
         let newobj = {};
         newobj[name] = item?.user?.id;
         return { ...pre, ...newobj }

     })
     console.log('onSelect', value);

 };
    const handlePageChange = (page) => {

        setpage((pre) => {
            return { ...pre, currentpage: page }
        })
    }
   

 
    const onChangeWorktype = (value, item) => {

        console.log(value);
        console.log(item);
        let name = 'worktype_id';
   
        setFilter((pre) => {
           
            let newobj = {};
            newobj[name] = value;
            return { ...pre, ...newobj }
   
        })

       /* console.log(`selected ${value}`);
        console.log(option);
    
        formData['work_id'] = value;
        setformData(formData);
        setworktype(value);
        console.log(formData);
        if(value==3 || value==2 || value==4){
          setworkordercodeedit(0);
        }else{
          setworkordercodeedit(1);
        }*/
    
      }
      const onSearch = (value) => {
        console.log('search:', value);
      };
      const markcomplete=async(item,key)=>{
       let data= await CustomerObj.WorkStatusUpdate({id:item.id,status:1});
       console.log(data);
       if(data==true){
            let list =[...customerList?.list];
            list[key]['status']=1;
            setReportList((pre)=>({...pre,list:list}));
            notification.success({message:'Status has been changed successfully'});
       }
         
      }


    return (
        <>
            <div class="callout callout-info bg-white">

                <form class="row row-cols-lg-auto g-3 align-items-center" onSubmit={(e) => { handleSubmit(e) }}>
                <div class="col-md-2">
                        <label><strong>User</strong></label>
                        <AutoComplete

                            style={{
                                width: "100%",

                            }}
                            options={useroptions}
                            onSelect={onSelect}
                            onSearch={handleSearch}
                            onChange={handleUserChange}
                            value={search}
                        >
                            <Input size="large" placeholder="User Search here" />

                        </AutoComplete>
                    </div>

                    <div class="col-md-2">
                        <br/>
                    <div class="form-check">
                    <Select
                showSearch
                placeholder="Select a Work Type"
                optionFilterProp="children"
                onChange={onChangeWorktype}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={workTypeData}
              />
                            </div>
                    </div>
                   

                    <div class="col-12">
                    <br/>
                        <button className="btn btn-primary" >Search</button>
                    </div>
                </form>
    </div>
            <div className="card mb-4">
                <div className="card-header">Task List
                    <div className="right-box"><Link className="btn btn-primary" to="/admin/task/add">Add New</Link></div>
                </div>
                <div className="card-body">
                    <div className="tab-content rounded-bottom">
                        
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th>User</th>                                    
                                    <th>Work Type</th>
                                    <th>Code</th>    
                                    <th>Status</th>                              
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customerList.list.map((item, itemKey) => {
                                        return (
                                            <tr key={item.id}>
                                                <td >{((paging.currentpage - 1) * paging.itemlimit) + (itemKey + 1)}</td>
                                              
                                                <td >{`${item?.customer?.first_name} ${item?.customer?.last_name}`}</td>                                               
                                                <td >{item?.workType?.work_name}</td>
                                                <td>{item.code}</td> 
                                                <td>
                                                    {item.status=="0" && <>Pending</>}
                                                    {item.status=="1" && <>Completed</>}
                                                </td>                                              
                                                <td >{common.dateFormat(item.createdAt)}</td>
                                                <td >
                                                    {item.status=="0" && <Link to="" onClick={(e)=>markcomplete(item,itemKey)}>Mark As Complete</Link>}

                                                </td>
                                            </tr>

                                        )

                                    })
                                }

                            </tbody>
                        </table>
                        <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultCurrent={paging.currentpage} total={customerList.totalrecord} onChange={handlePageChange} />
                    </div>
                </div>
            </div>
        </>
    )

}

export default Tasklist;